#content ion-toolbar {
  border-bottom: 1px solid #f5f5f5;
}

.dashboard-toolbar {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.back-button {
  color: #000 !important;
}

// .dashboard-toolbar ion-item {
//   --background: transparent;
//   --border-style: none;
// }

// .dashboard-toolbar ion-avatar {
//   --border-radius: 4px;
//   box-shadow: var(--box-shadow);
// }

