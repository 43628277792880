// Adds rounded edges on swipeable view when minimised
.react-swipeable-view-container {
  border-radius: 20px 20px 0 0;
}

// Make button clickable in front of swipeable action sheet
.react-swipeable-custom-button {
  z-index: 10;
}

.rounded-sm {
  border-radius: 5px !important;
}

.rounded-lg {
  border-radius: 10px !important;
}

.form-group label {color: #666 !important;}
