.auth-form {
  .form-control { 
    border-color: #FFF;
  }
}

.login-button {
  padding-top: 2px;
}

.authentication {
  .bg-sign-in {
    --background: url('../assets/images/pexels-ella-olsson-1640770.jpg') no-repeat center/cover fixed;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: RGBA(100,100,100,0.4);
    padding: 0;

    ion-row {
      height: 100%;

      ion-card {
        border: none;
        box-shadow: var(--box-shadow);
      }

      ion-card-content div {
        text-align: center;
      }

      ion-card-title {
        font-size: 1.6rem;
      }

      ion-img {
        padding: 20px 80px;
      }

      ion-text {
        font-size: small;
      }

      .card-footer {
        background-color: #fff;
        color: #757575;
      }

      ion-router-link {
        font-weight: 700;
      }
    }
  }
}
