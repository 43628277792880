.min-h-100 {
  min-height: 100% !important; 
}

.w-45 { width: 45% !important;}
.w-40 { width: 40% !important;}

.top-0 { top: 0 !important; }
.left-0 { left: 0 !important; }
.bottom-0 {bottom: 0 !important;}
.right-0 {right: 0 !important;}

.ios-notch-padding {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}
.safe-area-padding {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}
.safe-area-margin {
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: constant(safe-area-inset-bottom) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
}

.safe-area-top-padding {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}
.safe-area-top-margin {
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important;
}
.safe-area-bottom-padding {
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}
.safe-area-bottom-margin {
  margin-bottom: constant(safe-area-inset-bottom) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
}

.bg-light-cyan {
  background-color: $light-cyan;
}

.btn.btn-checkbox-toggle {
  border: 1px $dark solid;
  &.active {
    background-color: #fff3cd;
    border-color: #ffeeba;
    &::after {
      content: ' x';
      color: $warning;
      font-weight: bold;
    }
  }
}