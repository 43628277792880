@import './theme/fonts';
@import './theme/variables';

@import '~bootstrap/scss/bootstrap';

@import './theme/generic';

@import './theme/overrides';
@import './theme/main';
@import './theme/transitions';

@import '~swiper/swiper.scss';
@import '~swiper/modules/pagination/pagination.min.css';
@import '~swiper/modules/keyboard/keyboard.min.css';
@import '~@ionic/react/css/ionic-swiper.css';

body div#hs-eu-cookie-confirmation {position:fixed; top:auto; bottom:0; padding-bottom: 40px;}