.slide-in-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-in-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.slide-in-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-in-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 500ms, transform 500ms;
}
.slide-in-exit-done {
  opacity: 0;
  transform: translateX(100%);
}

.slide-out-enter, .slide-out-appear {
  opacity: 1;
  transform: translateX(0);
}
.slide-out-enter-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms, transform 500ms;
}
.slide-out-enter-done {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-out-exit {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-out-exit-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.swiper-wrapper {
  transition: transform 600ms;
  &.peak {
    transform: translate3d(-120px, 0px, 0px) !important; 
  }
}

.search-result-square {
  transition: display 400ms;
  &.hide {
    opacity: 0;
    bottom: 0;
    left: 0;
    
  }
}

.modal-push-enter-active, .modal-push-exit-active {
  transition: transform 300ms;
}

.modal-push-enter {
  // push new view onto stack from the right
  transform: translateX(100%);
  z-index: 20030 !important;
}

.modal-push-enter-active {
  transform: translateX(0%);
}

.modal-push-exit {
  transform: translateX(0%);
  z-index: 98 !important;
}

.modal-push-exit-active {
  transform: translateX(-30%);
}

.modal-pop-enter-active, .modal-pop-exit-active {
  transition: transform 300ms;
}

.modal-pop-enter {
  transform: translateX(-30%);
}

.modal-pop-enter-active {
  transform: translateX(0%);
}

.modal-pop-exit {
  transform: translateX(0%);
}

.modal-pop-exit-active {
  transform: translateX(100%);
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active, .fade-exit-active {
  transition: opacity 600ms linear;
}


// .fade-appear, fade-appear-active, fade-appear-done
// fade-enter, fade-enter-active, fade-enter-done
// fade-exit, fade-exit-active, fade-exit-done