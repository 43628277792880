
$primary: #1AB1AC;
$secondary: #0a1545;
$tertiary: #ab1ad1;
$danger: #F05858;
$cyan: #679b9b;
$light-cyan: #E9F8F7;
$light: #D8D9DA;
$dark: #2D323D;
$background: #F3F3F3;
$coolest-200: #B3FFE4;
$coolest-300: #00FFCC;
$cool-100: #00BFA6; //primary
$cool-200: #0F857C;
$cool-300: #01655E;
$grey-200: #F7FAFA;
$grey-300: #E4F0EE;
$grey-400: #c4c4c4;
$grey-500: #A3B5B2;
$grey-600: #70858B;
$grey-700: #525F62;
$grey-800: #323E48;
$grey-900: #79969E;
$grey-1000: #294352;
$light-grey: #DADADA;

$max-laptop-width: 1440px;
$max-tablet-width: 1023px;
$max-mobile-width: 767px;

$grey-1100: #F6F6F6; // Number is placeholder. What does number actually mean and how should we setup this color?
$grey-1103: #DEDEDE;

$theme-colors: (
  "grey400": $grey-400,
  "grey-1100": $grey-1100,
  "grey-1103": $grey-1103,
  "light-grey": $light-grey,
  "tertiary": $tertiary,
  "light-cyan": $light-cyan
);

$transition-1: all cubic-bezier(0.77, 0.00, 0.175, 1.0) .25s;
$enable-gradients: true;

$box-shadow-sm: 0 .125rem .25rem rgba(0, 0, 0, .25);
$text-muted: #666666;

$border-radius: 5px;
$border-radius-lg: 10px;

// $box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .25) !important;
// $theme-colors: map-merge( ( "primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark ), () );

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #F3F3F3;
  --ion-toolbar-background: #FFFFFF;
  --ion-backdrop-color: #FFFFFF;
  --ion-item-background: #FFFFFF;

  --bullet-background: #000;
  --bullet-background-active: #fff;

  --ion-background-color-rgb: 243,243,243;

  --ion-text-color: #2D323D;
  --ion-text-color-rgb: 45,50,61;

  --ion-color-step-50: #F3F3F3;
  --ion-color-step-100: #dfe0e1;
  --ion-color-step-150: #d5d6d8;
  --ion-color-step-200: #cbcccf;
  --ion-color-step-250: #c2c3c6;
  --ion-color-step-300: #b8b9bc;
  --ion-color-step-350: #aeafb3;
  --ion-color-step-400: #a4a6aa;
  --ion-color-step-450: #9a9ca1;
  --ion-color-step-500: #909398;
  --ion-color-step-550: #86898f;
  --ion-color-step-600: #7c7f86;
  --ion-color-step-650: #72767d;
  --ion-color-step-700: #686c74;
  --ion-color-step-750: #5f626b;
  --ion-color-step-800: #555961;
  --ion-color-step-850: #4b4f58;
  --ion-color-step-900: #41454f;
  --ion-color-step-950: #373c46;

  /** primary **/
  --ion-color-primary: #1ab1ac;
  --ion-color-primary-rgb: 26,177,172;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #179c97;
  --ion-color-primary-tint: #31b9b4;

  /** secondary **/
  --ion-color-secondary: #0a1545;
  --ion-color-secondary-rgb: 10,21,69;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #09123d;
  --ion-color-secondary-tint: #232c58;

  /** tertiary **/
  --ion-color-tertiary: #ab1ad1;
  --ion-color-tertiary-rgb: 171,26,209;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #9617b8;
  --ion-color-tertiary-tint: #b331d6;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #414d50;
  --ion-color-dark-rgb: 65, 77, 80;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2b3335;
  --ion-color-dark-tint: #4b585c;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #c6fcf5;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 244, 244, 244;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #fefefe;
  --ion-color-white-tint: #fff;

  --box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.24);
}
