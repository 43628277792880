ion-content {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.ios-notch-bottom-padding {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.ios-notch-bottom-margin {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}

ion-slide { 
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.app-navbar-notch {
  height: env(safe-area-inset-top);
}

#mainContent {
  background-color: #F3F3F3;
}

.bg-sign-in {
  --background: url('../assets/images/pexels-ella-olsson-1640770.jpg') no-repeat center/cover fixed;
}

.card {
  ion-content {
    --ion-background-color: transparent;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.6);
  background-color: rgba(210, 210, 210, 0.5);
}

.display-5 {
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 1.2;
}

ion-card {
  box-shadow: none;
  border: solid #efefef 1px;
}

.react-gravatar {
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  margin-right: 4px;
}

.form-group {
  label {
    color: #70858B;
  }
  input, .profile-data {
    color: #323E48;
  } 
}
.bg-dark {
  label {
    color: $grey-500;
  }

  input:read-only {
    //color: $grey-100;
  }
}

.trivia-bullets {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    padding-left: 1em;
    text-indent: -1em;
    padding-top: 1em;
  }

  li:before {
    content: "✔︎";
    color: $cool-100;
    padding-right: 8px;
  }
}

.crossed-line {
  text-decoration: line-through;
}

.nav-link.active {
  border-bottom: 2px $primary solid;
}

ion-menu {
  font-weight: 700;
}

.ion-no-border > ion-toolbar {
  border: none;
}

.field-error{
  color: red
}
.ribbon-corner {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon-corner::before,
.ribbon-corner::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
}
.ribbon-corner span {
  opacity: 0.8;
  position: absolute;
  display: block;
  width: 300px;
  padding: 2px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  background-color: $primary;
  color: #fff;
  font-weight: 700;
  font-size: small;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-corner-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-corner-top-left::before,
.ribbon-corner-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-corner-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-corner-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-corner-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-corner-top-right {
  top: 0px;
  right: 0px;
}
.ribbon-corner-top-right::before,
.ribbon-corner-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-corner-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-corner-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-corner-top-right span {
  left: -22px;
  top: 10px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-corner-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-corner-bottom-left::before,
.ribbon-corner-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-corner-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-corner-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-corner-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-corner-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-corner-bottom-right::before,
.ribbon-corner-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-corner-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-corner-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-corner-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

.dietitian-check, .custom-check {
  cursor: pointer;
  top: 10px;
  z-index: 3;

  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  // [type=radio] + .btn {
  //   opacity: 0.6;
  //   border: 3px solid $light;
  //   border-radius: 50%;
  //   background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>');
  //   background-color: $light;
  //   background-size: 2.7rem 2.7rem;
  //   background-repeat: no-repeat;
  //   height: 50px;
  //   width: 50px;
  //   transition: all 300ms;
  // }
  // &:hover .btn {
  //   background-color: $light;
  //   opacity: 0.8;
  //   transition: all 300ms;
  // }
  [type=radio]:checked + .btn {
    color: #fff;
    background-color: $success;
    border-color: $success;
    transition: all 300ms;
  }
}

.card-input-element {
  +.card {
    cursor: pointer;
    // transform: scale(1);
    // border: 1px solid ;
    transition: background-color 300ms;
  }
  &:hover+.card {
    // transform: scale(.99);
    transition: background-color 300ms;
  }
  &:checked+.card {
    // transform: scale(.99);
    // border: 3px solid var(--primary);
    background-color: RGBA(26, 177, 172, 0.2);
  }
}

.dot {
  height: 35px;
  width: 35px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding-top: 5px;
  font-size: 16px;
}

.form-progress-bar {
  margin-bottom: 6px;
  color: $light;
  padding-left: 0;

  .active {
    color: $primary;
  }

  .form-progress-step {
    list-style-type: none;
    font-size: 15px;
    width: 25%;
    position: relative;
    z-index: 1;
    font-weight: 400;
    text-align: center;

    &::before {
      content: '';
      width: 48px;
      height: 48px;
      line-height: 45px;
      display: block;
      font-size: 20px;
      background-color: $background;
      border: 2px solid $light;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      padding: 2px;
    }
    &::after {
      content: '';
      width: 100%;
      height: 4px;
      background: $light;
      position: absolute;
      left: 0;
      top: 22px;
      z-index: -1;
    }

    &:first-child::after {
      width: 50%;
      right: 0;
      left: auto;
    }
    &:last-child::after {
      width: 50%;
    }
    &.active::before {
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg>');
      background-color: $primary;
      background-size: 2.7rem 2.7rem;
      background-repeat: no-repeat;
      border: 2px solid $primary;
    }
    
    &.active::after {
      background: $primary;
    }
  }
}

.specialist-modal-card {
  .profile-overlay {
    top: calc(100% - 200px);
    padding: 0 1rem;
    &.show {
      top: 200px;
      padding: 0;
  bottom: 0;
    }
  }
}

.specialist-tile-card {
  .profile-overlay {
    top: calc(100% - 50px);
    padding: 0 1rem;
    &.show {
      top: 100px;
      padding: 0;
    }
  }
}
.profile-overlay {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 1rem;
  opacity: 0.8;
  transition: all 700ms;
  bottom: 0;
  &.show {
    padding: 0;
    opacity: 0.9;
    overflow-y: scroll;
    transition: all 500ms;
  }
}

.swiper-pagination {
  --bullet-background-active: var(--ion-color-primary, #1ab1ac);
  
  .swiper-pagination-bullet {
    opacity: 0.4;
  }
  .swiper-pagination-bullet-active {
    opacity: 0.7;
  }
}

#meal-menu {
  .meal-image {
    width: 80px;
    height: 80px;
  }
}


.specialist-card {
  width: 45vw;
  height: 45vw;
}

.fab-vertical-bottom {
  bottom: 120px;
}

.text-grey {
  color: #666 !important;
}
ion-tab-button {
  color: $dark;
  --color: $dark;
  --color-selected: $dark;
}

.text-light-grey {
  color: #BEBEBE !important;
}

.slides-modal .modal-wrapper {
  background: #fff;
}

ion-modal ion-content {
  background: #fff !important;
  --background: #fff !important;
}

.material-icons {
  vertical-align: middle !important;
  position: relative !important;
  top: -1px !important;
}

.underline {
  span {
    position: relative;
    padding-bottom: 3px;
  }
  span::after{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid;
    content: ""
  }
}