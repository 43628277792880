/* Breakpoint limits taken from Google Material Design guidelines */
/* See: https://material.google.com/layout/responsive-ui.html#responsive-ui-breakpoints */
/* Initial spacing for use across templates. Temporary. Needs review for best approach */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

body {
  width: 100%;
  color: #323E48;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  position: relative;
  transition: background-color 1.2s cubic-bezier(0.77, 0, 0.175, 1), color 1.2s cubic-bezier(0.77, 0, 0.175, 1); 
}

@media (min-width: 600px) {
  body {
    font-size: 1.125rem;
    line-height: 1.75rem; 
  } 
}

.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fw-400, .fw-normal {
  font-weight: 400 !important;
}
.fw-700, .fw-bold {
  font-weight: 700 !important;
}